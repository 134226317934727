<template>
	<!-- :key="$route.fullPath" 解决了路由前缀相同时跳转不刷新 -->
  <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  // watch: {
  //   '$i18n.locale'(newVal, oldVal) {
  //     console.log('newVal, oldVal====', newVal, oldVal)
  //     if (newVal !== oldVal) {
  //       this.reload()
  //     }
  //   },
  // },
  // methods: {
  //   reload(){
  //     window.location.reload();
  //   }
  // }
}
</script>

