const zh = {
    website: {
        Header:{
            mobileNum: '18059873357',
            workTime: '周一 ~ 周五, 8:30 - 17:30',
            companyInfo: {
                name: '厦门市数工建筑科技有限公司',
                address: '福建省厦门市湖里区',
                phone: '+18059873357',
                email: '18059873357@163.com',
                website: 'www.baidu.com'
            },
            contact: '联系我们',
            tab: {
                home: '首页',
                product: '产品中心',
                example: '案例中心',
                chat: '建筑人工智能(工程智脑)',
                news: '公司动态',
                about: '关于我们',
                buildPlus: '建筑行业平台(建筑加)',
                bimdesign: 'BIM技术服务',
                bimconstruction: 'BIM施工服务',
                engani: '工程动画制作',
                intelcons: '智能建造',
                OtherMenu: '其他菜单',
                OtherMenu1: '其他菜单1',
                OtherMenu2: '其他菜单2',
                OtherMenu3: '其他菜单3'
            }
        },
        Footer: {
            product: '产品中心',
            productList: {
                case1: 'BIM技术服务',
                case2: '工程动画制作',
                case3: '智能建造',
            },
            about: '关于我们',
            AllMobilize: '我们是一家基于前沿科技提供建筑工程信息模型、智慧工地、工程人工智能的建筑科技公司，致力于运用信息技术成果和制造业思维帮助建筑企业数字化转型升级，以实现建筑的数字化、工业化、绿色化、和全生命周期管理。',
            contact: '联系详情',
            serve: '服务专线：18059873357'

        },
        IndexView:{ 
            banner: {
                title: '智能建造，首选数工建科',
                subTitle: '国内领先的智能建造解决方案供应商，全方位高效帮助您的工程进行数字化。提供全过程数字化服务（BIM技术、智慧工地软硬件、工程人工智能），让工程企业迅速迈进智能建造时代！',
            },
            tabList: {
               name1: 'BIM技术服务',
               desc1: '全过程建筑信息模型服务', 
               name2: '智能建造',
               desc2: '智慧工地设备及数字建造', 
               name3: '建筑人工智能',
               desc3: '土木工程行业垂直人工智能应用', 
            //    name4: '关于我们',
            //    desc4: '一句话概括特点', 
            },
            advantage: {
                title: '核心优势',
                subTitle: '领先的智能建造解决方案供应商，我们的核心优势在于深度融合BIM（建筑信息模型）技术、智慧工地软硬件解决方案以及土木工程人工智能服务，为客户构建起一套高效、智能、可视化的全周期建筑管理体系。',
            },
            advantageList: {
               name1: '一站式解决方案',
               desc1: 'BIM+智慧工地+人工智能一站式服务', 
               name2: '极具竞争力的价格',
               desc2: '我们提供极具竞争力的服务价格', 
               name3: '永久售后保障',
               desc3: '所有产品均提供专项售后保障服务群', 
               name4: '灵活的服务定制能力',
               desc4: '根据不同客户的需求提供个性化的服务方案', 
            },
            service: {
                title: '我们的服务',
                subTitle: '我们为客户提供BIM、智慧工地、人工智能的全链条专业服务。我们将卓越的专业服务与对客户业务的深入了解相结合，以帮助客户应对和解决企业面临智能建造的问题与挑战。我们视服务质量为第一生命，而客户项目的成功以及与客户之间建立并不断完善的长期合作关系是我们的核心价值之所在。',
                // subTitle1: '',
            },
            serviceList: {
               name1: '全过程应用的BIM技术',
               desc1: '实现项目设计的精准建模与冲突预检，显著提升设计与施工质量。', 
               name2: '智慧工地软硬件解决方案',
               desc2: '覆盖现场管理、安全监控、环境监测等多个维度，助力项目安全、绿色、智慧施工。', 
               name3: '工程人工智能',
               desc3: '为工程决策提供科学依据，推动行业向更加精细化、智能化方向发展。', 
            //    name4: '多页面工作',
            //    desc4: '标签栏可切换，不必为了新内容而被迫跳转界面，多项工作内容并行处理', 
            }
        },
        ProductView: {
            title: '自主创新跨平台企业级解决方案',
            subTitle: '光明网曾考虑过通过原生App来实现移动化，但是由于无法复用原始业务流程和数据就放弃了，直到选用了云适配整体解决方案后，可以很方便的在Enterplorer上使用一个帐号登录我们的系统，通过VPN随时进入内网。',
            brumb: {
                home: '首页',
                product: '产品中心'
            },
            showTitle: '解决方案案例展示',
            showSubTitle: '全球独创专利技术：一行代码部署，帮助企业快速安全地将现有PC版网页适配成HTML5跨屏网页，跨平台的企业统一办公 门户，快捷的移动适配开发能力，完备的数据安全保护',
        },
        ExampleView: {
            title: '客户案例',
            brumb: {
                home: '首页',
                example: '客户案例'
            },
            showTitle: '聚焦用户，放眼全球',
            showSubTitle: '从2022年至今，我们已经服务了数十个海内外的知名客户，包括中国建筑、中国铁路等世界500强企业，积累了深厚的项目经验。',
            showSubTitle1: '每一位客户都是我们最宝贵的伙伴，我们携手共创价值，共筑美好未来。'
        },
        NewsView: {
            title: '公司动态',
            brumb: {
                home: '首页',
                news: '公司动态'
            },
            showTitle: '最近新闻',
            showSubTitle: '结合了庞大土木工程数据库的专属应用：工程智脑，一位可以随时解答并且专业可靠的顾问，面向土木工程的人工智能应用于近期发布。',
            showSubTitle1: '合作签约仪式暨全国跨屏行动启动大会”在北京成功举办。',
        },
        NewsDetailsView: {
            brumb: {
                news: '公司动态',
                actived: '文章详情'
            },
            viewMore: '查看更多',
            author: '作者',
            publishTime: '发布时间'
        },
        AboutView: {
            title: '关于我们',
            brumb: {
                home: '首页',
                about: '关于我们'
            },
            showTitle: '关于数工建科你需要知道哪些？',
            showSubTitle: '数工建科是中国领先的工程数字化解决方案供应商，致力于用数字技术焕发建筑新生命，使建造过程更加智慧化，服务建设数字中国。',
            showSubTitle1: '从2022年至今，工程AI应用已经吸引了超过数万从业者的加入；BIM技术已积累了上百个工程项目经验；数字建造产品也成功应用于各种大型工程项目，包括中国建筑、中国铁路等世界500强企业。期待与您的合作！',
            companyBrief: '<p style="margin: 0;">国家一级建造师（市政专业）</p><p style="margin: 0;">国家一级建造师（建筑专业）</p><p style="margin: 0;">中国图学学会BIM技能等级证书（三级）</p><p style="margin: 0;">中级工程师</p>',
            companyAuthor: '蔡开发',
            companyAuthorDesc: '数工建筑科技创始人',
            ourTenet: {
                title: '我们的宗旨',
                subTitle: '让人们无论使用任何设备都能安全高效获取信息',
                subTitle1: 'One Web，Any Device',
                quality: {
                    name: '品质',
                    title: '全球领先的HTML5解决方案供应商，安全高效解决企业移动化',
                    desc1: '快捷的移动适配开发',
                    desc2: '统一的办公通讯门户',
                    desc3: '完善的安全管理平台'
                },
                liability: {
                    name: '责任',
                    title: '国产最流行的开源HTML5前端框架，组件丰富，为HTML5开发加速',
                    desc1: '持续改进',
                    desc2: '追求卓越品质',
                    desc3: '为顾客创造价值',
                },
                honest: {
                    name: '诚信',
                    title: '全球领先的HTML5解决方案供应商，安全高效解决企业移动化',
                    desc1: '平等包容',
                    desc2: '互利共赢',
                    desc3: '与事业伙伴同成长',
                }
            },
            ourTeam: {
                title: '我们的团队',
                subTitle: '核心成员均毕业于中国985高校，并且取得建造师、BIM从业资格证书、工程师等从业资质，具备扎实的技术基础。',
                subTitle1: '也有来自行业的技术大拿。'
            }
        }
    },
    aiChatView: {
        Header: {
            tabList: {
                home: '首页',
                chat: '工程智脑'
            },
            loginBox: {
                login: '登录',
                register: '注册',
                loginAccount: '账号密码登录',
                loginPhone: '手机号登录',
                loginQrcode: '扫码登录',
                username: '请输入帐户名',
                password: '请输入密码',
                code: '请输入验证码',

                confirmPassword: '确认密码',
                email: '邮箱',
                phone: '请输入手机号',
                getCode: '获取验证码',
                registerSuccess: '注册成功',
                autoLoginBtn: '自动登录',
                forgetPasswordBth: '忘记密码',
                registerBtn: '注册账户',
                cancelBtn: '取 消',
                confirmBtn: '确 定',
                logoutBtn: '退出登录',
            },
            
        },
        ChatView: {
            
        },
        tips: {
            codeLoading: '验证码发送中...',
            codeSuccess: '验证码发送成功',
            codeError: '验证码发送失败',
            codeError1: '验证码错误',
            codeError2: '验证码已过期',
            codeError3: '验证码已失效',
            getCodeError: '获取验证码失败',
            requestFailedMsg: '请求出现错误，请稍后再试',
            noLogin: '您还未登录,请尝试重新登录!',
            confirmIsLogin: '确定退出登录吗？',
            welcome: '欢迎您',
            inputMsgHolder: '有问题尽管问我',
            selectRole: '请选择角色',
            sendSuccess: '发送成功',
            sendError: '发送失败',
            sendError1: '发送失败，请稍后再试',
            sendError2: '发送失败，请检查网络连接',
            noAskNums: '次数已用完'
        }
    }
}
export default zh;
