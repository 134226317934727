const en = {
    website: {
        Header:{
            mobileNum: '18059873357',
            workTime: 'Monday to Friday, 8:30 AM - 5:30 PM',
            companyInfo: {
                name: 'Xiamen Shugong Architectural Technology Co., Ltd.',
                address: 'Huli District, Xiamen City, Fujian Province',
                phone: '+18059873357',
                email: '18059873357@163.com',
                website: 'www.baidu.com'
            },
            contact: 'Contact',
            tab: {
                home: 'Home',
                product: 'Products Center',
                example: 'Case Center', // Portfolio Center
                chat: 'Engineering AI Brain',
                news: 'Company News',
                about: 'About',
                buildPlus: 'Construction Plus (Platform)',
                bimdesign: 'BIM Design Services',
                bimconstruction: 'BIM Construction Services',
                engani: 'Engineering Animation Services',
                intelcons: 'Intelligent Construction Integrator',
                OtherMenu: 'OtherMenu',
                OtherMenu1: 'OtherMenu1',
                OtherMenu2: 'OtherMenu2',
                OtherMenu3: 'OtherMenu3'
            }
        },
        Footer: {
            product: 'Products Center',
            productList: {
                case1: '家用电梯',
                case2: '别墅电梯',
                case3: '观光电梯',
            },
            about: 'About',
            AllMobilize: '云适配(AllMobilize Inc.) 是全球领先的HTML5企业移动化解决方案供应商，由前微软美国总部IE浏览器核心研发团队成员及移动互联网行业专家在美国西雅图创立.',
            contact: 'Contact Details',
            serve: 'Service Hotline: 18059873357'

        },
        IndexView:{ 
            banner: {
                title: '企业移动化，首选云适配',
                subTitle: '全球领先的HTML5企业移动化解决方案供应商，安全高效的帮助您的企业移动化。云适配企业浏览器Enterploer,让企业安全迈进移动办公时代！',
            },
            tabList: {
               name1: '家用电梯',
               desc1: '一句话概括特点', 
               name2: '别墅电梯',
               desc2: '一句话概括特点', 
               name3: '观光电梯',
               desc3: '一句话概括特点', 
               name4: '关于我们',
               desc4: '一句话概括特点', 
            },
            advantage: {
                title: '核心优势',
                subTitle: '全球领先HTML5企业移动化解决方案供应商，由前微软美国总部IE浏览器核心研发团队成员及移动互联网行业专家在美国西雅图创立,获得了微软创投的扶持以及晨兴资本、IDG资本、天创资本等国际顶级风投机构的投资。',
            },
            advantageList: {
               name1: '简单的适配过程',
               desc1: '用户可快速学习适配开发，通过丰富的组件库完成页面功能的移动化适配。', 
               name2: '简单的适配过程',
               desc2: '用户可快速学习适配开发，通过丰富的组件库完成页面功能的移动化适配。', 
               name3: '简单的适配过程',
               desc3: '用户可快速学习适配开发，通过丰富的组件库完成页面功能的移动化适配。', 
               name4: '简单的适配过程',
               desc4: '用户可快速学习适配开发，通过丰富的组件库完成页面功能的移动化适配。', 
            },
            service: {
                title: '我们的服务',
                subTitle: '全球领先HTML5企业移动化解决方案供应商，由前微软美国总部IE浏览器核心研发团队成员及移动互联网行业专家在美国西雅图创立',
                subTitle1: '获得了微软创投的扶持以及晨兴资本、IDG资本、天创资本等国际顶级风投机构的投资。',
            },
            serviceList: {
               name1: '多页面工作',
               desc1: '标签栏可切换，不必为了新内容而被迫跳转界面，多项工作内容并行处理', 
               name2: '多页面工作',
               desc2: '标签栏可切换，不必为了新内容而被迫跳转界面，多项工作内容并行处理', 
               name3: '多页面工作',
               desc3: '标签栏可切换，不必为了新内容而被迫跳转界面，多项工作内容并行处理', 
               name4: '多页面工作',
               desc4: '标签栏可切换，不必为了新内容而被迫跳转界面，多项工作内容并行处理', 
            }
        },
        ProductView: {
            title: '自主创新跨平台企业级解决方案',
            subTitle: '光明网曾考虑过通过原生App来实现移动化，但是由于无法复用原始业务流程和数据就放弃了，直到选用了云适配整体解决方案后，可以很方便的在Enterplorer上使用一个帐号登录我们的系统，通过VPN随时进入内网。',
            brumb: {
                home: 'Home',
                product: 'Products Center'
            },
            showTitle: '解决方案案例展示',
            showSubTitle: '全球独创专利技术：一行代码部署，帮助企业快速安全地将现有PC版网页适配成HTML5跨屏网页，跨平台的企业统一办公 门户，快捷的移动适配开发能力，完备的数据安全保护',
        },
        ExampleView: {
            title: 'Case Center',
            brumb: {
                home: 'Home',
                example: 'Case Center'
            },
            showTitle: '全球首创 自主创新',
            showSubTitle: 'Enterplorer Studio是一套面向企业级移动信息化建设的开发平台。集聚开发、测试、',
            showSubTitle1: '打包、发布于一体的移动化开发综合平台。'
        },
        NewsView: {
            title: 'Company News',
            brumb: {
                home: 'Home',
                news: 'Company News'
            },
            showTitle: 'Recent News',
            showSubTitle: '云适配与中建材信息技术股份有限公司（以下简称“中建信息”）联合举办的“战略',
            showSubTitle1: '合作签约仪式暨全国跨屏行动启动大会”在北京成功举办。',
        },
        NewsDetailsView: {
            brumb: {
                news: 'Company News',
                actived: 'Article Details'
            },
            viewMore: 'View More',
            author: 'Author',
            publishTime: 'Release Time'
        },
        AboutView: {
            title: 'About',
            brumb: {
                home: 'Home',
                about: 'About'
            },
            showTitle: '关于云适配你需要知道哪些？',
            showSubTitle: '云适配(AllMobilize Inc.) 是全球领先的HTML5企业移动化解决方案供应商，由前微软美国总部IE浏览器核心研发团队成员及移动互联网行业专家在美国西雅图创立，并获得了微软创投的扶持以及晨兴资本、IDG资本、天创资本等国际顶级风投机构的投资。',
            showSubTitle1: '从2012年至今，云适配的开放技术平台已经吸引了超过40万开发者加入；云适配跨屏云也成功应用于超过30万家企业网站，包括微软、联想等世界500强企业，光明网、中国青年报等知名媒体，清华、北大、中科大等知名大学，以及中国政府网、中国共产党网等政府机构。2014年6月，比尔·盖茨先生访华时特地观看了云适配的技术演示，并给予高度的赞赏。',
            companyBrief: '国家“千人计划”特聘专家、中国企业级HTML5产业联盟主席、HTML5国际标准制定者之一、微软总部IE浏览器核心研发,成功发布了IE8、IE9、IE10,参与了下一代互联网标准HTML5国际标准制定以及IE中HTML5引擎的设计。',
            companyAuthor: '吴总',
            companyAuthorDesc: '奥里斯电梯有限公司创始人',
            ourTenet: {
                title: '我们的宗旨',
                subTitle: '让人们无论使用任何设备都能安全高效获取信息',
                subTitle1: 'One Web，Any Device',
                quality: {
                    name: '品质',
                    title: '全球领先的HTML5解决方案供应商，安全高效解决企业移动化',
                    desc1: '快捷的移动适配开发',
                    desc2: '统一的办公通讯门户',
                    desc3: '完善的安全管理平台'
                },
                liability: {
                    name: '责任',
                    title: '国产最流行的开源HTML5前端框架，组件丰富，为HTML5开发加速',
                    desc1: '持续改进',
                    desc2: '追求卓越品质',
                    desc3: '为顾客创造价值',
                },
                honest: {
                    name: '诚信',
                    title: '全球领先的HTML5解决方案供应商，安全高效解决企业移动化',
                    desc1: '平等包容',
                    desc2: '互利共赢',
                    desc3: '与事业伙伴同成长',
                }
            },
            ourTeam: {
                title: '我们的团队',
                subTitle: '成员既有超级学霸（来自Harvard、Google、香港科技大学、中国科技大学），',
                subTitle1: '也有来自行业的技术大拿。'
            }
        }
    },
    aiChatView: {
        Header: {
            tabList: {
                home: 'Home',
                chat: 'Engineering AI Brain'
            },
            loginBox: {
                login: 'Login',
                register: 'Register',
                loginAccount: 'LoginAccount',
                loginPhone: 'LoginPhone',
                loginQrcode: 'LoginQrcode',
                username: 'Enter your account name',
                password: 'Enter your password',
                code: 'Enter the verification code',

                confirmPassword: 'Confirm Password',
                email: 'Email',
                phone: 'Enter your phone number',
                getCode: 'Get Verification Code', // Retrieve Verification Code
                registerSuccess: 'Registration Successful',
                autoLoginBtn: 'Automatic Login',
                forgetPasswordBth: 'Forgot Password',
                registerBtn: 'Sign Up',
                cancelBtn: 'Cancel',
                confirmBtn: 'OK', // Confirm
                logoutBtn: 'Sign Out', // Log Out
            },
            
        },
        ChatView: {
            
        },
        tips: {
            codeLoading: 'Sending Verification Code...',
            codeSuccess: 'Verification Code Sent Successfully', // The Verification Code Has Been Sent
            codeError: 'Failed to Send Verification Code', // Verification Code Sending Failed
            codeError1: 'Verification Code Error', // Incorrect Verification Code
            codeError2: 'Verification Code Has Expired', // The Verification Code has Expired
            codeError3: 'The Verification Code Is Invalid', // Verification code is no longer valid
            getCodeError: 'Failed to Retrieve Verification Code', // Unable to Get Verification Code
            requestFailedMsg: 'An error occurred in the request, please try again later.',
            noLogin: 'You are not logged in, please log in again.',
            confirmIsLogin: 'Are you sure you want to log out?', // Confirm to log out?
            welcome: 'Welcome',
            inputMsgHolder: '"Feel free to ask me if you have any question', // Please don't hesitate to ask if you have any questions
            selectRole: 'Please select a role', // Choose a role, please
            sendSuccess: 'Sent successfully', // The message has been sent successfully
            sendError: 'Send faile', // Failed to send
            sendError1: 'Send failed, please try again later.',
            sendError2: 'Send failed, please check your internet connection.',
            noAskNums: 'The limit has been reached' // You have used up your attempts
        }
    }
}
export default en;
