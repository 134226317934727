import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/product',
        name: 'product',
        component: () => import('../views/Product/ProductView.vue'),
    },
    {
        path: '/product/productId/:productId',
        name: 'productDetails',
        component: () => import("../views/Product/ProductDetailsView.vue")
    },
    {
        path: '/example',
        name: 'example',
        component: () => import('../views/ExampleView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/News/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/News/NewsDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('../views/Chat/ChatView.vue')
    },
    {
        path: '/buildplus',
        name: 'buildplus',
        component: () => import('../views/Product/BuildPlusView.vue')
    },
    {
        path: '/bimdesign',
        name: 'bimdesign',
        component: () => import('../views/Product/BIMDesignView.vue')
    },
    {
        path: '/bimconstruction',
        name: 'bimconstruction',
        component: () => import('../views/Product/BIMConstructionView.vue')
    },
    {
        path: '/engani',
        name: 'engani',
        component: () => import('../views/Product/EngAniView.vue')
    },
    {
        path: '/intelcons',
        name: 'intelcons',
        component: () => import('../views/Product/IntelConsView.vue')
    }
]

const router = new VueRouter({
    mode: 'history', //去除url里的#
    routes
})

export default router
