import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import ElementUI from 'element-ui';

import {i18n} from './i18n/index.js';

//样式文件需要单独引入
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import { EventBus } from '@/utils/EventBus';
Vue.prototype.$eventBus = EventBus;



//Vue使用QRCode插件，生成二维码
import QRCode from 'qrcode'
Vue.prototype.$QRCode = QRCode


Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
