// 设置localStorage
export const setStorage = function(key, obj) {
    window.localStorage.setItem(key, obj)
};
 
// 获取localStorage
export const getStorage = function(key) {
    return window.localStorage.getItem(key);
};
 
// 移除localStorage
export const removeStorage = function(key) {
    window.localStorage.removeItem(key)
};

// 清除所有localStorage
export const clearStorage = function() {
    window.localStorage.clear()
};
 
// 获取浏览器默认语言
export const getBrowserLang = function() {
  let browserLang = navigator.language ? navigator.language: navigator.browserLanguage;
  let defaultBrowserLang = '';
    if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
        defaultBrowserLang = 'zh'
    } else {
        defaultBrowserLang = 'en'
    }
    return defaultBrowserLang
};